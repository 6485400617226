'use client';

import {defaultFeatureFlags, type FeatureFlags} from '#/cms/schemas/feature-flags.schema';
import {createStore} from 'zustand';
import getStoreProvider from './getStoreProvider';

export type FeatureFlagsState = {
  featureFlags: FeatureFlags;
};

const defaultInitState: FeatureFlagsState = {
  featureFlags: defaultFeatureFlags,
};

const createFeatureFlagStore = (initState: FeatureFlagsState = defaultInitState) => {
  return createStore<FeatureFlagsState>()(() => initState);
};

const {StateProvider, useCurrentStore} = getStoreProvider('FeatureFlagStore', createFeatureFlagStore);

export const FeatureFlagStoreProvider = StateProvider;
export const useFeatureFlagStore = useCurrentStore;
