'use client';

import {createStore} from 'zustand';
import getStoreProvider from './getStoreProvider';
import {type EventsSettings} from '#/cms/schemas/events-settings.schema';

const createEventsSettingsStore = (initState: EventsSettings = {}) => {
  return createStore<EventsSettings>()(() => initState);
};

const {StateProvider, useCurrentStore} = getStoreProvider('EventsSettingsStore', createEventsSettingsStore);

export const EventsSettingsStoreProvider = StateProvider;
export const useEventsSettings = useCurrentStore;
