'use client';

import {type FeatureFlags} from '#/cms/schemas/feature-flags.schema';
import urls from '#/urls';
import {type PepIconName} from '@calvium/pep-icons';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import {usePathname} from 'next/navigation';
import {useCallback, useMemo, type ComponentProps} from 'react';
import {useCurrentMode} from '../store/clientEnvStore';
import {useFeatureFlagStore} from '../store/featureFlagsStore';
import {useSidebarStore} from '../store/sidebarStore';
import NavigableButton from './NavigableButton';
import {PepIcon} from './PepIcon';
import {Heading2, SmallText} from './Text';
import {useCurrentThemeVariant} from '../store/themeColorsStore';
import {getTextColor} from '#/utils/get-color';
import {useUiStrings} from '#/features/store/uiStrings';
import {type UIStrings} from '#/cms/schemas/uiStrings.schema';
import {useAppLogo} from '../store/webAppInfoStore';
import {useMobileAppLogo} from '../store/WebMobileAppPromoStore';
import MobileAppButton from '#/features/components/MobileAppButton';

type MenuEntry = {
  id: keyof UIStrings['general']['sideBar'];
  text: string;
  pepIconName: PepIconName;
  route: string;
  activeRouteRe: RegExp;
  enabledByFlag?: keyof FeatureFlags['web'];
  className?: string;
};

const menuEntries: MenuEntry[] = [
  {
    id: 'home',
    text: 'Home',
    pepIconName: 'Home',
    route: urls.home,
    activeRouteRe: /^\/$/,
    enabledByFlag: 'displayHome',
  },
  {
    id: 'search',
    text: 'Search',
    pepIconName: 'Search',
    route: urls.search,
    activeRouteRe: /\/search/,
    enabledByFlag: 'displaySearch',
    className: 'desktop-only',
  },
  {
    id: 'sights',
    text: 'Venues',
    pepIconName: 'LocationPin',
    route: urls.sights,
    activeRouteRe: /(\/sights)|(\/sight\/[a-zA-Z0-9]+)/,
    enabledByFlag: 'displaySights',
  },
  {
    id: 'events',
    text: 'Events',
    pepIconName: 'Calendar',
    route: urls.events,
    activeRouteRe: /(\/events)|(\/event\/[a-zA-Z0-9]+)/,
    enabledByFlag: 'displayEvents',
  },
  {
    id: 'places',
    text: 'Places',
    pepIconName: 'Maps',
    route: urls.places,
    activeRouteRe: /(\/places)|(\/place\/[a-zA-Z0-9]+)/,
    enabledByFlag: 'displayPlaces',
  },
  {
    id: 'more',
    text: 'More',
    pepIconName: 'Information',
    route: urls.more,
    activeRouteRe: /\/more/,
  },
];

const Sidebar = ({
  className,
  title,
  children,
  isMobile,
  ...props
}: ComponentProps<'div'> & {
  title?: string;
  isMobile?: boolean;
}) => {
  const pathname = usePathname();
  const {featureFlags} = useFeatureFlagStore();
  const uiStrings = useUiStrings();
  const enabledMenuEntries = useMemo(
    () => menuEntries.filter(entry => (entry.enabledByFlag ? featureFlags.web[entry.enabledByFlag] : true)),
    [featureFlags.web]
  );
  const enabledMenuEntriesWithUIStrings = useMemo(() => {
    return enabledMenuEntries.map(entry => {
      return {
        ...entry,
        text: uiStrings.general.sideBar[entry.id] || entry.text,
      };
    });
  }, [uiStrings.general.sideBar, enabledMenuEntries]);
  const currentMode = useCurrentMode();
  const theme = useCurrentThemeVariant();
  let {collapsed} = useSidebarStore();
  const {toggle} = useSidebarStore();
  const {logoAlt, logoUrl} = useAppLogo();
  const {mobileAppLogo, mobileAppLogoAlt} = useMobileAppLogo();
  const mobileAppButtonLabel = uiStrings.general.mobileAppButtonLabel;

  const handleStopPreviewMode = useCallback(async () => {
    await fetch('/api/disable-preview');
    location.replace(urls.home);
  }, []);

  if (isMobile) {
    collapsed = false;
  }

  return (
    <aside
      aria-expanded={!collapsed}
      className={classNames(
        'fixed top-0 z-10 flex h-dvh flex-col justify-between gap-y-1 overflow-hidden border-r border-midground3 bg-background px-4 pt-8 transition-all duration-300 ease-in-out',
        collapsed ? 'w-20' : 'w-64',
        isMobile && 'md:hidden',
        'mobile:absolute mobile:w-full',
        className
      )}
      {...props}
    >
      <nav className={classNames('flex flex-col gap-4 mobile:gap-3')}>
        {/* logo and collapse button for desktop */}
        <Link href={urls.home} className="hidden flex-row md:flex">
          {logoUrl && <Image src={logoUrl} alt={logoAlt} width={64} height={64} className="rounded-xl" />}
          {!collapsed ? <p className={'ml-3 text-2xl font-bold leading-none text-foreground'}>{title}</p> : null}
        </Link>
        <button
          aria-label={collapsed ? 'expand sidebar' : 'collapse sidebar'}
          className={classNames(
            'flex h-8 w-8 min-w-8 items-center justify-center rounded-full border border-midground4 stroke-midground4 hover:border-primary hover:stroke-primary',
            collapsed ? 'mx-auto' : 'ml-auto',
            'mobile:hidden'
          )}
          onClick={toggle}
        >
          {collapsed && <PepIcon iconName="SidebarExpand" strokeColor="inherit" />}
          {!collapsed && <PepIcon iconName="SidebarCollapse" strokeColor="inherit" />}
        </button>

        {/* mobile only nav title and search bar */}
        <Heading2 className="mobile-only -mt-4 mb-2">Menu</Heading2>
        {featureFlags.web.displaySearch && (
          <Link
            className="mobile-only mb-2 flex h-9 w-full flex-row items-center justify-start gap-x-4 rounded-full border border-midground3 bg-background px-3.5 py-2.5"
            href={urls.search}
          >
            <PepIcon className="shrink-0" iconName="Search" strokeColor="midground4" />
            <SmallText color={'foreground'}>{uiStrings.general.sideBar.search}</SmallText>
          </Link>
        )}

        {/* main menu entries */}
        {enabledMenuEntriesWithUIStrings.map((entry, idx) => {
          const isActive = pathname.match(entry.activeRouteRe);
          return (
            <Link
              aria-current={isActive ? 'page' : undefined}
              title={collapsed ? entry.text : undefined}
              key={`menu-entry-${idx}`}
              className={classNames(
                'group flex h-9 w-full flex-row items-center justify-start gap-x-4 rounded-full px-3.5 py-2.5 hover:bg-secondary',
                isActive ? 'bg-primary stroke-light' : 'stroke-midground4 hover:stroke-light',
                entry.className
              )}
              href={entry.route}
            >
              <PepIcon className="shrink-0" iconName={entry.pepIconName} strokeColor={'inherit'} />
              {!collapsed && (
                <SmallText className="group-hover:text-light" color={getTextColor(theme, !!isActive, true)}>
                  {entry.text}
                </SmallText>
              )}
            </Link>
          );
        })}

        {/* mobile app promo */}
        <NavigableButton
          className="mobile-only mt-3 flex flex-row items-center justify-center gap-x-4 rounded-3xl bg-primary px-4 py-2"
          href={urls.mobileapp}
          aria-label={uiStrings.general.mobileAppButtonLabel}
        >
          {!!mobileAppLogo && (
            <Image
              className="my-auto"
              src={mobileAppLogo}
              alt={mobileAppLogoAlt}
              a-hidden="true"
              height={48}
              width={48}
            />
          )}
          <div className="text-light">{uiStrings.general.mobileAppButtonLabel}</div>
        </NavigableButton>
      </nav>
      <div
        className={classNames(
          'mx-4 flex flex-grow border-b border-midground3',
          currentMode !== 'preview' && 'desktop-only'
        )}
      />

      {/* preview control */}
      <div className="mb-5 mt-4 flex w-full shrink-0 flex-col items-center justify-center gap-y-6">
        {currentMode === 'preview' && (
          <button
            className="relative flex h-10 w-full items-center gap-3 rounded-full bg-primary mobile:w-56"
            onClick={handleStopPreviewMode}
          >
            <PepIcon
              className={classNames(collapsed ? 'mx-auto' : 'absolute left-5')}
              iconName="Bulb"
              strokeColor={'background'}
            />
            {!collapsed && (
              <SmallText color={'background'} className="mx-auto text-nowrap px-12">
                {uiStrings.general.sideBar.stopPreview}
              </SmallText>
            )}
          </button>
        )}
        {children}
        {!isMobile && (
          <MobileAppButton
            href={urls.mobileapp}
            label={mobileAppButtonLabel}
            logoUrl={mobileAppLogo}
            logoAlt={mobileAppLogoAlt}
          />
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
