import {z} from 'zod';

const featureFlagItem = (enabled: boolean) => z.boolean().default(enabled);

const WebFeatureFlagsSchema = z
  .object({
    displayAppPromotion: featureFlagItem(false),
    displayEvents: featureFlagItem(false),
    displayEventSubmissionModal: featureFlagItem(false),
    displayFacilities: featureFlagItem(false),
    displayHome: featureFlagItem(false),
    displayHunts: featureFlagItem(false),
    displayItems: featureFlagItem(false),
    displayPlaces: featureFlagItem(false),
    displaySearch: featureFlagItem(false),
    displaySights: featureFlagItem(false),
    displayTrails: featureFlagItem(false),
    hasDarkMode: featureFlagItem(false),
    webEvents: featureFlagItem(false),
    webFacilities: featureFlagItem(false),
    webHunts: featureFlagItem(false),
    webItems: featureFlagItem(false),
    webSights: featureFlagItem(false),
    webTrails: featureFlagItem(false),
  })
  .default({});

const GeneralFeatureFlagsSchema = z
  .object({
    amenities: featureFlagItem(false),
    appDarkTheme: featureFlagItem(true),
    appImageZoom: featureFlagItem(true),
    appShare: featureFlagItem(true),
    ar: featureFlagItem(false),
    audio: featureFlagItem(true),
    categoryClasses: featureFlagItem(false),
    events: featureFlagItem(true),
    favourites: featureFlagItem(true),
    huntCategories: featureFlagItem(false),
    hunts: featureFlagItem(false),
    instagramFilters: featureFlagItem(false),
    itemCategories: featureFlagItem(true),
    items: featureFlagItem(true),
    kioskMode: featureFlagItem(false),
    links: featureFlagItem(false),
    mapSatellite: featureFlagItem(true),
    openingTimes: featureFlagItem(true),
    placeLogo: featureFlagItem(true),
    placeSightListLayoutSelect: featureFlagItem(true),
    promotions: featureFlagItem(false),
    search: featureFlagItem(true),
    sightAdditionalInfo: featureFlagItem(true),
    sightAddress: featureFlagItem(true),
    sightCategories: featureFlagItem(true),
    sightDirectionsLink: featureFlagItem(true),
    sightRelatedApp: featureFlagItem(true),
    slideSets: featureFlagItem(true),
    trailAdditionalInfo: featureFlagItem(true),
    trailCategories: featureFlagItem(false),
    webAppEnabled: featureFlagItem(true),
  })
  .default({});

export const FeatureFlagsSchema = z.object({
  general: GeneralFeatureFlagsSchema,
  web: WebFeatureFlagsSchema,
});
export type FeatureFlags = z.infer<typeof FeatureFlagsSchema>;

export const defaultFeatureFlags = FeatureFlagsSchema.parse({});
