import {useCallback} from 'react';
import useCookieConsentStore from '../store/cookieConsentStore';
import ReactGA from 'react-ga4';
import {useParams} from 'next/navigation';

const DEBUG_LOG_ANALYTICS = false;

export enum AnalyticsEventCategory {
  Sights = 'sights',
  Events = 'events',
  Places = 'places',
  ExternalLink = 'externalLink',
  MoreSection = 'moreSection',
}

export enum AnalyticsEventAction {
  EventView = 'eventView',
  FilterByPlace = 'filterByPlace',
  FilterByCategory = 'filterByCategory',
  FilterByAmenity = 'filterByAmenity',
  FilterByTime = 'filterByTime',
  FilterByText = 'filterByText',
  Click = 'click',
}

export type EventOptions = Record<string, string> & {
  category: AnalyticsEventCategory;
  action: AnalyticsEventAction;
  label: string;
};

const useAnalytics = () => {
  const {analytics: enabled} = useCookieConsentStore();
  const params = useParams();

  const initializeAnalytics = useCallback(
    (measurementId: string) => {
      if (enabled && !ReactGA.isInitialized) ReactGA.initialize(measurementId);
    },
    [enabled]
  );

  const registerPageView = useCallback(
    (pathname: string) => {
      if (enabled && ReactGA.isInitialized) {
        const [, collectionId = '', featureId = ''] = [...pathname.matchAll(/\/([^\/]*)/g)].map(
          match => match[1] ?? ''
        );
        const extraParams = params.id ? {collectionId, featureId} : {};
        const fieldObject = {hitType: 'pageview', page: pathname, ...extraParams};
        if (DEBUG_LOG_ANALYTICS) {
          console.log('registerPageView', fieldObject);
        }
        ReactGA.send(fieldObject);
      }
    },
    [enabled, params.id]
  );

  const registerEvent = useCallback(
    ({category, action, label, ...options}: EventOptions) => {
      if (enabled && ReactGA.isInitialized) {
        const params = {event_category: category, event_label: label, ...options};
        if (DEBUG_LOG_ANALYTICS) {
          console.log('registerEvent', action, params);
        }
        ReactGA.event(action, params);
      }
    },
    [enabled]
  );

  return {initializeAnalytics, registerPageView, registerEvent};
};

export default useAnalytics;
